.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {

  &:hover,
  &:focus {
    text-decoration: none;
    // .pageTile_details:before {
    //   background: $primary-color;
    // }
    .pageTile_details {
      background: $color-blue2;
    }

    .pageTile__title,
    .pageTile_desc {
      color: $color-yellow;
    }
    
    .pageTile__title span::after {
      border-color: $color-yellow;
    }
    
    .tile__bg{
      transform: scale(1.02);
    }

  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: $weight-bold;
  line-height: 1.1;
  margin: 0;
  color: $color-blue2;
  span{
    //border-bottom: 2px solid $color-blue2;
    position: relative;
    &::after{
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      border-bottom: 2px solid $color-blue2;
    }
    //padding-bottom: 5px;
  }
  // text-decoration: underline;
  // line-height: 1.2;

}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.9rem;
  color: $color-blue2;
  margin-top: 1rem;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: 1.5rem 0.8rem;
  text-align: left;
  background-color: $color-yellow;
  margin-top: 0.8rem;
  text-align: center;
  box-shadow: 3px 6px 6px rgba(0,0,0,0.5);
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left, rgba(#fff, 0.3), rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}
